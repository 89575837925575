import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import PageWrapper from "../components/PageWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, Box } from "../components/Core";
import { FormattedMessage, FormattedDate } from 'react-intl';

const StyledText = styled(Text)`
  background: rgb(234, 233, 242);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px;
`

export const TermsPageTemplate = ({ title, date, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section">
      <div className="pt-5 pt-lg-0"></div>
      <Section className="pb-4">
        <Container>
          <Row className="justify-content-md-center text-md-center">
            <Col lg="12">
              <Title>{title}</Title>
              <Box className="d-flex justify-content-center">
                <StyledText>
                  <FormattedMessage
                    id="lastUpdate"
                    defaultMessage="This page has been last updated at"
                  />{" "}
                  <FormattedDate
                    value={new Date(date)}
                    month="long"
                    day="numeric"
                    year="numeric"
                  />
                </StyledText>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
      <div className="container content">
        <PageContent className="content pb-5" content={content} />
      </div>
    </section>
  );
};

TermsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const TermsPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <PageWrapper footerDark>
      <TermsPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        content={post.html}
      />
    </PageWrapper>
  );
};

TermsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TermsPage;

export const termsPageQuery = graphql`
  query TermsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date
      }
    }
  }
`;
